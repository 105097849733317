import { Box } from '@chakra-ui/react';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

import { Card } from './Card';

export const FullPageMessage: FC<{ message: string | ReactNode }> = memo(({ message }) => {
  return (
    <Box
      alignItems="center"
      bg={'brand.beige'}
      display="flex"
      minH="100vh"
      px={{ base: '4', lg: '8' }}
      py="12"
      w="full"
    >
      <Box maxW="md" mx="auto">
        <Card>{message}</Card>
      </Box>
    </Box>
  );
});
