import { Box, Flex, Spacer, Text } from '@chakra-ui/react';
import { Button, useAppActions } from '@ours/web-utils';
import type { FC } from 'react';
import { memo, useCallback } from 'react';

import { FullPageMessage } from '../../components/FullPageMessage';

export interface Props {}
export const InvalidPermissionsMessage: FC = memo(() => {
  const { onLogout } = useAppActions();
  const onClick = useCallback(() => {
    onLogout();
  }, [onLogout]);

  return (
    <>
      <FullPageMessage
        message={
          <Box maxW="md" mx="auto">
            <Text textAlign="center">You do not have permission to view this application</Text>
            <Spacer my="4" />
            <Flex justifyContent="center">
              <Button
                as="button"
                onClick={onClick}
                testId="InvalidPermissionsMessage"
                type="button"
              >
                Loggout
              </Button>
            </Flex>
          </Box>
        }
      />
    </>
  );
});
