import { GuideRoutes, isCustomer } from '@ours/utils';
import {
  LoginComponentContainer,
  UserStateModals,
  initApp,
  useAnalyticsEvent,
  useAppActions,
  useAppStateValue,
  useBrandedToast,
  useUserRole,
} from '@ours/web-utils';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { memo, useEffect } from 'react';

import { InvalidPermissionsMessage } from '../features/InvalidPermissionsMessage/InvalidPermissionsMessage';
import { getStageAndVersion } from '../lib/getStageAndVersion';

import { FullPageLoader } from './FullPageLoader';

const { stage, version } = getStageAndVersion();
initApp({ appKind: 'admin', stage, type: 'ON_INIT', version });
const noAuthRoutes = [GuideRoutes.LogOut, GuideRoutes.RequestedLink, GuideRoutes.Login] as string[];
export const AppWithAuth: FC<any> = memo(({ Component, Layout, pageProps }) => {
  const { onInApp } = useAppActions();
  const { trackEvent } = useAnalyticsEvent();
  const appState = useAppStateValue();
  const role = useUserRole();
  const toast = useBrandedToast();

  useEffect(() => {
    onInApp({ toast, trackEvent });
  }, [onInApp, toast, trackEvent]);
  const { pathname } = useRouter();

  if (noAuthRoutes.includes(pathname)) {
    return (
      <Layout>
        <Component {...pageProps} />
      </Layout>
    );
  }

  if (appState === 'loading') {
    return <FullPageLoader variant="beige" />;
  }

  if (appState === 'unauthenticated') {
    return <LoginComponentContainer appKind="admin" />;
  }

  if (isCustomer(role)) {
    return <InvalidPermissionsMessage />;
  }

  return (
    <Layout>
      <UserStateModals />
      <Component {...pageProps} />
    </Layout>
  );
});
