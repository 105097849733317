import type { BoxProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import * as React from 'react';

export const Card = React.memo((props: BoxProps) => (
  <Box
    bg="white"
    px={{ base: '4', md: '10' }}
    py="8"
    rounded={{ sm: 'lg' }}
    shadow="base"
    {...props}
  />
));
