import { noop } from '@ours/utils';
import type { FC, ReactNode } from 'react';
import { memo } from 'react';

import { AnalyticsContextProvider } from './analyticsContext';

interface Props {
  children: ReactNode;
}

export const AnalyticsContextProviderNoop: FC<Props> = memo(({ children }) => {
  return (
    <AnalyticsContextProvider identifyUserId={noop} startEvent={noop} trackEvent={noop}>
      {children}
    </AnalyticsContextProvider>
  );
});
