import { Box, Spinner } from '@chakra-ui/react';
import type { FC } from 'react';
import { memo } from 'react';

interface IFullPageLoaderProps {
  variant: 'base' | 'beige';
}

const variants = {
  base: {
    bg: 'background.light',
  },
  beige: {
    bg: 'brand.beige',
  },
};

export const FullPageLoader: FC<IFullPageLoaderProps> = memo(({ variant = 'base' }) => {
  return (
    <Box
      alignItems="center"
      bg={variants[variant].bg}
      display="flex"
      justifyContent="center"
      minH="100vh"
      px={{ base: '4', lg: '8' }}
      py="12"
      w="full"
    >
      <Spinner color="blue.500" emptyColor="gray.200" size="xl" speed="0.65s" thickness="4px" />
    </Box>
  );
});
