/* eslint-disable import/no-duplicates */
/* eslint-disable import/first */
/* eslint-disable import/order */
/* eslint-disable react-memo/require-memo */
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { atPolyfill } from '@ours/web-utils';

atPolyfill();

import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/react';
import type { NextPageWithLayout } from '@ours/types';
import {
  AnalyticsContextProviderNoop,
  GlobalFonts,
  GlobalPreload,
  GlobalStyles,
  UnexpectedErrorBoundary,
  extendTheme,
  getApolloClient,
} from '@ours/web-utils';

import type { AppProps } from 'next/app';
import type { FC } from 'react';
import { memo, useMemo } from 'react';

import { AppWithAuth } from '../components/AppWithAuth';

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

interface IMemoizedProvidersProps {
  Component: any;
  Layout: any;
  pageProps: any;
}

const MemoizedProviders: FC<IMemoizedProvidersProps> = memo(({ Component, Layout, pageProps }) => {
  return (
    <GlobalFonts>
      <ChakraProvider resetCSS theme={extendTheme}>
        <GlobalStyles />
        <GlobalPreload />

        <UnexpectedErrorBoundary>
          <ApolloProvider client={client}>
            <AnalyticsContextProviderNoop>
              <AppWithAuth Component={Component} Layout={Layout} pageProps={pageProps} />
            </AnalyticsContextProviderNoop>
          </ApolloProvider>
        </UnexpectedErrorBoundary>
      </ChakraProvider>
    </GlobalFonts>
  );
});

const client = getApolloClient({
  app: 'admin',
  ssr: false,
  stage: (process.env.STAGE || process.env.NEXT_PUBLIC_STAGE || '') as any,
  version: process.env.NEXT_PUBLIC_VERSION || '',
});

const MyApp: FC<AppPropsWithLayout> = ({ Component, pageProps }) => {
  const Layout = useMemo(() => Component.Layout ?? ((page: any) => page), [Component.Layout]);

  return <MemoizedProviders Component={Component} Layout={Layout} pageProps={pageProps} />;
};

export default MyApp;
